var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.modal ? 'okvir-modal' : 'okvir'},[(_vm.loading)?_c('v-overlay',{staticStyle:{"z-index":"100"},attrs:{"absolute":_vm.absolute,"opacity":_vm.opacity,"value":_vm.overlay}},[(_vm.loading)?_c('div',{staticClass:"text-center",staticStyle:{"position":"absolute","z-index":"101","top":"50%","-ms-transform":"translateY(-50%)","transform":"translateY(-50%)"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"86","color":"primary"}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"resizable",style:(_vm.modal
      ? 'text-align: center; border-radius: 25px !important;'
      : ("text-align: center;margin-left: " + _vm.margin))},[_c('v-card',{staticClass:"pb-0 mb-0",style:(_vm.modal ? 'border-radius: 25px !important' : ''),attrs:{"tile":"","color":"white"}},[(_vm.modal)?_c('v-card-title',{staticClass:"d-flex",staticStyle:{"vertical-align":"middle","background-color":"#1577da","height":"58px","color":"white"}},[_c('span',{staticClass:"text-h5",staticStyle:{"font-weight":"bold"}},[_vm._v("Unos otpisa")])]):_vm._e(),_c('v-form',{ref:"primkaForm",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{style:(_vm.modal
          ? 'display: flex; margin-top: 15px; margin-left: 5px; margin-right: 5px;'
          : 'display: flex; margin-top: 5px;')},[(_vm.locations.length > 1)?_c('v-autocomplete',{staticClass:"pr-2",attrs:{"hide-details":"","outlined":"","dense":"","label":"Prodajno mjesto","items":_vm.locations,"item-text":"name","item-value":"value","auto":"","return-object":"","rules":[_vm.rules.req]},on:{"change":function($event){return _vm.getWarehouses()}},model:{value:(_vm.selectedLocation),callback:function ($$v) {_vm.selectedLocation=$$v},expression:"selectedLocation"}}):_vm._e(),(_vm.warehouses.length > 1)?_c('v-autocomplete',{staticClass:"pr-2",attrs:{"hide-details":"","outlined":"","dense":"","label":"Skladište","items":_vm.warehouses,"item-text":"name","item-value":"value","return-object":"","rules":[_vm.rules.req]},model:{value:(_vm.selectedWarehouse),callback:function ($$v) {_vm.selectedWarehouse=$$v},expression:"selectedWarehouse"}}):_vm._e(),_c('date-picker',{ref:"datepicker",staticClass:"datetimepicker",attrs:{"clearable":false,"prefix-class":"xmx","placeholder":"Datum","type":"date","valueType":"timestamp","format":"DD.MM.YYYY","default-value":new Date(),"popup-style":{ zIndex: 9999 },"rules":[_vm.rules.req],"append-to-body":true},model:{value:(_vm.invoiceDate),callback:function ($$v) {_vm.invoiceDate=$$v},expression:"invoiceDate"}}),_c('v-text-field',{staticClass:"pl-2",attrs:{"hide-details":"","outlined":"","label":"Broj otpisa","dense":"","rules":[_vm.rules.req]},model:{value:(_vm.brojPrimke),callback:function ($$v) {_vm.brojPrimke=$$v},expression:"brojPrimke"}})],1)]),_c('div',{ref:"table",staticClass:"table-sm"}),_c('v-card-actions',{staticClass:"pa-0 ma-0 mr-5"},[_c('v-row',[_c('v-col',{staticStyle:{"align-items":"right","text-align":"right"}},[_c('v-menu',{staticStyle:{"background-color":"red"},attrs:{"top":"","origin":"center center","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn warningButton mt-2 mb-2 mr-2",attrs:{"id":"success"},on:{"touchstart":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{staticClass:"custom-list"},[_c('v-list-item',{staticClass:"btn dangerButton custom-list-item",on:{"click":_vm.clearData,"touchstart":_vm.clearData}},[_c('v-list-item-title',[_c('span',[_vm._v("Obriši sve")])])],1)],1)],1),(_vm.modal)?_c('v-btn',{staticClass:"btn dangerButton mt-2 mb-2 mr-2",attrs:{"id":"success"},on:{"click":function($event){return _vm.closeModal()},"touchstart":function($event){return _vm.closeModal()}}},[_vm._v("Odustani")]):_vm._e(),_c('v-btn',{staticClass:"btn successButton mt-2 mb-2",attrs:{"id":"success"},on:{"click":_vm.presubmit,"touchstart":_vm.presubmit}},[_vm._v("Spremi")])],1)],1)],1)],1)],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('fab-menu'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }